quill-editor {
  @apply w-full bg-white border-2 border-neutral-100 rounded;
}

quill-view .ql-editor {
  @apply p-0;
}

/* TOOLBAR */

/* font dropdown names */
.ql-snow .ql-picker.ql-font .ql-picker-label[data-value=serif]:before,
.ql-snow .ql-picker.ql-font .ql-picker-item[data-value=serif]:before {
  content: "Serif";
}

.ql-snow .ql-picker.ql-font .ql-picker-label[data-value=display]:before,
.ql-snow .ql-picker.ql-font .ql-picker-item[data-value=display]:before {
  content: "Display";
}

/* size dropdown names */
.ql-snow .ql-picker.ql-size .ql-picker-label[data-value=extra-small]:before,
.ql-snow .ql-picker.ql-size .ql-picker-item[data-value=extra-small]:before {
  content: "Extra Small";
}

.ql-snow .ql-picker.ql-size .ql-picker-label[data-value=small]:before,
.ql-snow .ql-picker.ql-size .ql-picker-item[data-value=small]:before {
  content: "Small";
}

.ql-snow .ql-picker.ql-size .ql-picker-label[data-value=extra-large]:before,
.ql-snow .ql-picker.ql-size .ql-picker-item[data-value=extra-large]:before {
  content: "Extra Large";
}

.ql-snow .ql-picker.ql-size .ql-picker-label[data-value=2xl]:before,
.ql-snow .ql-picker.ql-size .ql-picker-item[data-value=2xl]:before {
  content: "2XL";
}

.ql-snow .ql-picker.ql-size .ql-picker-label[data-value=3xl]:before,
.ql-snow .ql-picker.ql-size .ql-picker-item[data-value=3xl]:before {
  content: "3XL";
}

/* custom hubspot form button */
.ql-snow .ql-formats .ql-hubspot-form {
  display: flex;
  align-items: center;
  width: auto;
}
.ql-snow .ql-formats .ql-hubspot-form:before {
  content: 'Hubspot Form';
  font-size: 12px;
}

/* DEFAULTS */
.ql-editor {
  @apply font-normal text-base text-black leading-normal;
}

/* FONT SIZES */
.ql-snow .ql-picker.ql-size .ql-picker-item:before {
  @apply text-base;
}

.ql-editor .ql-size-extra-small,
.ql-snow .ql-picker.ql-size .ql-picker-item[data-value=extra-small]:before {
  @apply text-xs;
}

.ql-editor .ql-size-small,
.ql-snow .ql-picker.ql-size .ql-picker-item[data-value=small]:before {
  @apply text-sm;
}

.ql-editor .ql-size-large,
.ql-snow .ql-picker.ql-size .ql-picker-item[data-value=large]:before {
  @apply text-lg;
}

.ql-editor .ql-size-extra-large,
.ql-snow .ql-picker.ql-size .ql-picker-item[data-value=extra-large]:before {
  @apply text-xl;
}

.ql-editor .ql-size-2xl,
.ql-snow .ql-picker.ql-size .ql-picker-item[data-value=2xl]:before {
  @apply text-2xl;
}

.ql-editor .ql-size-3xl,
.ql-snow .ql-picker.ql-size .ql-picker-item[data-value=3xl]:before {
  @apply text-3xl;
}

/* THEMES */
/* ACS */
.theme-Alaska .ql-editor,
.theme-Alaska .ql-snow .ql-picker.ql-font .ql-picker-label:before,
.theme-Alaska .ql-snow .ql-picker.ql-font .ql-picker-item:before {
  @apply font-alaskaSans;
}

.theme-Alaska .ql-editor .ql-font-serif,
.theme-Alaska .ql-snow .ql-picker.ql-font .ql-picker-label[data-value=serif]:before,
.theme-Alaska .ql-snow .ql-picker.ql-font .ql-picker-item[data-value=serif]:before {
  @apply font-alaskaSerif;
}

.theme-Alaska .ql-editor .ql-font-display,
.theme-Alaska .ql-snow .ql-picker.ql-font .ql-picker-label[data-value=display]:before,
.theme-Alaska .ql-snow .ql-picker.ql-font .ql-picker-item[data-value=display]:before {
  @apply font-alaskaDisplay;
}

.theme-Alaska .ql-editor a:not(.btn) {
  @apply text-sm font-bold leading-[150%] text-tertiary-100;
}

/* AzamaraCruiseSale */
.theme-Azamara .ql-editor,
.theme-Azamara .ql-snow .ql-picker.ql-font .ql-picker-label:before,
.theme-Azamara .ql-snow .ql-picker.ql-font .ql-picker-item:before {
  @apply font-azamaraSans;
}

.theme-Azamara .ql-editor .ql-font-serif,
.theme-Azamara .ql-snow .ql-picker.ql-font .ql-picker-label[data-value=serif]:before,
.theme-Azamara .ql-snow .ql-picker.ql-font .ql-picker-item[data-value=serif]:before {
  @apply font-azamaraSerif;
}

.theme-Azamara .ql-editor .ql-font-display,
.theme-Azamara .ql-snow .ql-picker.ql-font .ql-picker-label[data-value=display]:before,
.theme-Azamara .ql-snow .ql-picker.ql-font .ql-picker-item[data-value=display]:before {
  @apply font-azamaraDisplay;
}

.theme-Azamara .ql-editor a:not(.btn) {
  @apply text-sm font-bold leading-[150%] text-tertiary-100;
}

/* CI */
.theme-CI .ql-editor,
.theme-CI .ql-snow .ql-picker.ql-font .ql-picker-label:before,
.theme-CI .ql-snow .ql-picker.ql-font .ql-picker-item:before {
  @apply font-CISans;
}

.theme-CI .ql-editor .ql-font-serif,
.theme-CI .ql-snow .ql-picker.ql-font .ql-picker-label[data-value=serif]:before,
.theme-CI .ql-snow .ql-picker.ql-font .ql-picker-item[data-value=serif]:before {
  @apply font-CISerif;
}

.theme-CI .ql-editor .ql-font-display,
.theme-CI .ql-snow .ql-picker.ql-font .ql-picker-label[data-value=display]:before,
.theme-CI .ql-snow .ql-picker.ql-font .ql-picker-item[data-value=display]:before {
  @apply font-CIDisplay;
}

.theme-CI .ql-editor a:not(.btn) {
  @apply text-sm font-bold leading-[150%] text-tertiary-100;
}

.theme-CI .ql-editor a.blog-card {
  @apply no-underline text-black;
}

/* OCS */
.theme-Oceania .ql-editor,
.theme-Oceania .ql-snow .ql-picker.ql-font .ql-picker-label:before,
.theme-Oceania .ql-snow .ql-picker.ql-font .ql-picker-item:before {
  @apply font-oceaniaSans;
}

.theme-Oceania .ql-editor .ql-font-serif,
.theme-Oceania .ql-snow .ql-picker.ql-font .ql-picker-label[data-value=serif]:before,
.theme-Oceania .ql-snow .ql-picker.ql-font .ql-picker-item[data-value=serif]:before {
  @apply font-oceaniaSerif;
}

.theme-Oceania .ql-editor .ql-font-display,
.theme-Oceania .ql-snow .ql-picker.ql-font .ql-picker-label[data-value=display]:before,
.theme-Oceania .ql-snow .ql-picker.ql-font .ql-picker-item[data-value=display]:before {
  @apply font-oceaniaDisplay;
}

.theme-Oceania .ql-editor a:not(.btn) {
  @apply text-sm font-bold leading-[150%] text-tertiary-100;
}

/* RCS */
.theme-Regent .ql-editor,
.theme-Regent .ql-snow .ql-picker.ql-font .ql-picker-label:before,
.theme-Regent .ql-snow .ql-picker.ql-font .ql-picker-item:before {
  @apply font-regentSans;
}

.theme-Regent .ql-editor .ql-font-serif,
.theme-Regent .ql-snow .ql-picker.ql-font .ql-picker-label[data-value=serif]:before,
.theme-Regent .ql-snow .ql-picker.ql-font .ql-picker-item[data-value=serif]:before {
  @apply font-regentSerif;
}

.theme-Regent .ql-editor .ql-font-display,
.theme-Regent .ql-snow .ql-picker.ql-font .ql-picker-label[data-value=display]:before,
.theme-Regent .ql-snow .ql-picker.ql-font .ql-picker-item[data-value=display]:before {
  @apply font-regentDisplay;
}

.theme-Regent .ql-editor a:not(.btn) {
  @apply text-sm font-bold leading-[150%] text-tertiary-100;
}

/* SCS */
.theme-Silversea .ql-editor,
.theme-Silversea .ql-snow .ql-picker.ql-font .ql-picker-label:before,
.theme-Silversea .ql-snow .ql-picker.ql-font .ql-picker-item:before {
  @apply font-silverseaSans;
}

.theme-Silversea .ql-editor .ql-font-serif,
.theme-Silversea .ql-snow .ql-picker.ql-font .ql-picker-label[data-value=serif]:before,
.theme-Silversea .ql-snow .ql-picker.ql-font .ql-picker-item[data-value=serif]:before {
  @apply font-silverseaSerif;
}

.theme-Silversea .ql-editor .ql-font-display,
.theme-Silversea .ql-snow .ql-picker.ql-font .ql-picker-label[data-value=display]:before,
.theme-Silversea .ql-snow .ql-picker.ql-font .ql-picker-item[data-value=display]:before {
  @apply font-silverseaDisplay;
}

.theme-Silversea .ql-editor a:not(.btn) {
  @apply text-sm font-bold leading-[150%] text-tertiary-100;
}


/* WCS */
.theme-Windstar .ql-editor,
.theme-Windstar .ql-snow .ql-picker.ql-font .ql-picker-label:before,
.theme-Windstar .ql-snow .ql-picker.ql-font .ql-picker-item:before {
  @apply font-windstarSans;
}

.theme-Windstar .ql-editor .ql-font-serif,
.theme-Windstar .ql-snow .ql-picker.ql-font .ql-picker-label[data-value=serif]:before,
.theme-Windstar .ql-snow .ql-picker.ql-font .ql-picker-item[data-value=serif]:before {
  @apply font-windstarSerif;
}

.theme-Windstar .ql-editor .ql-font-display,
.theme-Windstar .ql-snow .ql-picker.ql-font .ql-picker-label[data-value=display]:before,
.theme-Windstar .ql-snow .ql-picker.ql-font .ql-picker-item[data-value=display]:before {
  @apply font-windstarDisplay;
}

.theme-Windstar .ql-editor a:not(.btn) {
  @apply text-sm font-bold leading-[150%] text-tertiary-100;
}

/* Promo Slider */
.ql-snow .ql-editor .promo-slider a.opt-out {
  @apply font-bold uppercase tracking-wider leading-tight text-black text-base no-underline;
}

.ql-snow .ql-editor .promo-slider a {
  @apply no-underline;
}

.ql-snow .ql-editor .promo-slider h2.opt-out {
  @apply text-base;
}